<template>
    <header :class="headerClasses" class="md:w-full z-[900] fixed top-0 shadow-card shadow-none text-[#4500DC]">
        <slot></slot>
    </header>
</template>

<script>
export default {
    data() {
        return {
            isScrolled: false,
        };
    },
    computed: {
        headerClasses() {
            return {
                'bg-white': this.isScrolled,
                'text-black': this.isScrolled,
            };
        },
    },
    methods: {
        handleScroll() {
            this.isScrolled = window.scrollY > 60;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
};
</script>

<style>
/* Add any additional styles you need here */
</style>
