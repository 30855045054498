<!-- resources/js/MyComponent.vue -->
<template>
    <div class="bg-[#F8F8F8] block md:flex h-full relative mx-auto rounded-md">
        <div class="relative h-full w-full md:w-[800px]">
            <div class="w-full h-full overflow-x-hidden">
                <div class="relative w-full">
                    <div class="md:w-3/4 mx-auto px-4 md:px-4 text-gray-700 gap-x-2 sm:block border-gray-200 mt-12">
                        <div class="flex w-full mt-2 flex-wrap mx-auto rounded-md mb-5">
                            <button @click="selectType = 'formal'" :class="[selectType == 'formal' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200 rounded-s-lg hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                formal
                            </button>
                            <button @click="selectType = 'informal'" :class="[selectType == 'informal' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200  hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                informal
                            </button>
                            <button @click="selectType = 'persuasive'" :class="[selectType == 'persuasive' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200 hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                persuasive
                            </button>
                            <button @click="selectType = 'friendly'" :class="[selectType == 'friendly' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200 hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                friendly
                            </button>
                            <button @click="selectType = 'confident'" :class="[selectType == 'confident' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200 hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                confident
                            </button>
                            <button @click="selectType = 'inspirational'" :class="[selectType == 'inspirational' ? 'text-white bg-[#F2AAAF]' : 'text-black']"  class="px-3 py-2 text-sm font-semibold border border-gray-200 rounded-r-lg hover:bg-[#F2AAAF] hover:text-white focus:z-10 focus:ring-2 focus:ring-[#77AFFB] focus:text-black">
                                inspirational
                            </button>
                        </div>
                        <label for="rawtext">Sentence you want to rewrite</label>
                        <textarea rows="4" id="rawtext" v-model="rawtext" class="w-full mx-auto border-gray-200 rounded-md mt-1" :class="{ 'border-red-500': isTextareaEmpty }" @input="isTextareaEmpty = rawtext.trim() === ''" required></textarea>
                        <div v-if="isTextareaEmpty" class="text-xs text-red-500"> {{ errorMessage }} </div>

                        <p class="text-xs text-right text-gray-700" :class="rawtext.length > 250 ? 'text-red-400':''">{{rawtext.length}}/250</p>
                        <div class="flex flex-row gap-x-2">
                            <div class="flex" title="Upload Now">
                                <label  for="file" class="w-30 flex flex-row items-center px-4 py-2 rounded-lg border cursor-pointer">
                                    <img :src="'/images/upload_file.svg'" alt="copy.svg" width="26px" height="26px">
                                    <input @change="handleFileUpload" type="file" multiple name="fields" ref="file" accept=".doc,.docx,.pdf,.txt" id="file" class="hidden"/>
                                </label>
                            </div>
                            <div @click="pasteTextFromClipboard" class="rounded-lg border cursor-pointer w-30 px-4 py-2">
                                <img :src="'/images/paste_text.svg'" alt="paste.svg" width="26px" height="26px">
                            </div>
                        </div>



                        <div v-if="showEditor" class="flex-1 bg-white border h-[250px] relative" style="height: 250px !important;">
                            <QuillEditor theme="snow" v-model:content="editortext" contentType="text" toolbar="minimal" class="h-auto"/>
                            <ActionButtonsComponent :text-to-copy="editortext" :editortext="editortext"/>
                        </div>

                        <div class="w-full py-2">
                            <div id="cf-container"></div>
                        </div>
                        <div class="text-center">
                            <button @click="doParaphrase()" :disabled="loading" class="w-full text-center flex flex-wrap mt-4 disable:bg-[#EB5C66] bg-[#EB5C66] hover:bg-[#EB5C66] text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline gap-x-2" type="button">
                                <svg v-if="loading" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                               <span class="mx-auto text-center"> Rewrite Sentence </span>
                            </button>
                            <p class="my-2">Your result will be shown below</p>
                        </div>
                        <div class="pb-3">
                            <span class="font-bold">Text Length:</span>
                            <div class="inline-flex rounded-md shadow-sm mx-3" role="group">
                                <button @click="selectMode = 'rephrase'" :class="[selectMode == 'rephrase' ? 'text-white bg-[#F2AAAF]' : 'text-white bg-[#F2E3EE]']" type="button" class="px-4 py-2 text-sm font-medium border border-gray-200 rounded-s-lg hover:text-black focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                                    No Change
                                </button>
                                <button @click="selectMode = 'shorten'" :class="[selectMode == 'shorten' ? 'text-white bg-[#F2AAAF]' : 'text-white bg-[#F2E3EE]']" type="button" class="px-4 py-2 text-sm font-medium border-t border-b border-gray-200 hover:text-text-black focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Shorten
                                </button>
                                <button @click="selectMode = 'expend'" :class="[selectMode == 'expend' ? 'text-white bg-[#F2AAAF]' : 'text-white bg-[#F2E3EE]']" type="button" class="px-4 py-2 text-sm font-medium border border-gray-200 rounded-e-lg hover:text-text-black focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                                    Expend
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref } from "vue";
import axios from "axios";
import ActionButtonsComponent from '@/Components/ActionButtonsComponent.vue';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker';

export default {
    components:{
        QuillEditor,
        ActionButtonsComponent,
        ErrorPopupComponent
    },
    props:{
        translations:Object,
    },
    data(){
        return {
            sitekey: '0x4AAAAAAAfG90yDOhb1v7bZ',
            token: null,
            rawtext: '',
            editortext: null,
            loading: false,
            isTextareaEmpty: false,
            errorMessage: "This field is required.",
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",
            isDropdownVisible: false,
            selectedOption: 'English',
            selectMode:'rephrase',
            selectType:'formal',
            showEditor: false,
        };
    },
    mounted(){
        this.renderCf();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeMount() {
        this.cfInit();
        document.removeEventListener('click', this.handleClickOutside);
    },
    methods:{
        async pasteTextFromClipboard() {
            try {
                const text = await navigator.clipboard.readText();
                this.rawtext += text;
            } catch (err) {
                console.error("Failed to read clipboard contents:", err);
            }
        },
        async handleFileUpload(event) {
            const files = event.target.files;
            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                        // Handle DOCX file using mammoth.js
                        this.readDocxFile(file);
                    } else if (file.type === 'text/plain') {
                        // Handle TXT file using FileReader
                        this.readTxtFile(file);
                    }  else if (file.type === 'application/pdf') {
                        // Handle PDF file using pdf.js
                        this.readPdfFile(file);
                    }
                    else {
                        console.error('Unsupported file format. Please upload .docx files.');
                    }
                }
            }
        },
        readDocxFile(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;

                // Use mammoth.js to convert the DOCX content to plain text
                mammoth.extractRawText({ arrayBuffer })
                    .then(result => {
                        this.rawtext = result.value; // Insert the extracted text into the rawtext data property
                    })
                    .catch(error => {
                        console.error('Error reading the DOCX file:', error);
                    });
            };

            reader.readAsArrayBuffer(file); // Read the DOCX file as an ArrayBuffer
        },

        readTxtFile(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.rawtext = event.target.result;  // Insert the text content into `rawtext`
            };
            reader.readAsText(file);
        },

        async readPdfFile(file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const arrayBuffer = event.target.result;
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

                let extractedText = '';
                for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
                    const page = await pdf.getPage(pageNumber);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items.map(item => item.str).join(' ');
                    extractedText += `\nPage ${pageNumber}:\n${pageText}\n`;
                }

                this.rawtext = extractedText;
            };

            reader.readAsArrayBuffer(file);
        },

        cfInit(){
            const e = document.createElement("script"), t = "https://challenges.cloudflare.com/turnstile/v0/api.js";
            e.src = `${t}?onload=onloadTurnstileCallback&render=explicit`, e.async = !0, e.defer = !0, document.head.appendChild(e);
        },
        updateText(event, index) {
            // Update the text in the data model
            this.$set(this.editortext, index, event.target.innerText);
        },
        renderCf(){
            let _this = this;
            window.onloadTurnstileCallback = function () {
                turnstile.render('#cf-container', {
                    sitekey: _this.sitekey,
                    callback: function(token) {
                        _this.verify(token);
                    },
                });
            };
        },
        adjustHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to auto to calculate the new height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
        },
        verify(token){
            this.token = token;
        },
        doParaphrase(){
            this.loading = true;
            axios.post("/tool/sentenceRewriter", {
                capcha_token: this.token,
                rawtext:this.rawtext,
                lang:'english',
                mode: this.selectMode,
                type: this.selectType,
            })
                .then(response=>{
                    console.log('Response:', response.data.content);
                    this.loading = false;
                    if (response.data.content.length){
                        this.editortext = response.data.content;
                        this.showEditor = true;
                    }
                    turnstile.reset('#cf-container')
                })
                .catch(e => {
                    console.error('Error show:', e);
                    this.loading =false;
                    turnstile.reset('#cf-container');

                    const lettersOnly = /^[a-zA-Z\s]+$/;

                    if (e.response && e.response.status === 422) {
                        this.serverErrorMessage = e.response.data.message;
                        this.yourErrorHeaderVariable = "Error 422";
                    } else if (this.rawtext.trim() === '') {
                        this.errorMessage = "Input Text Required"; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Input Text Required.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    } else if (this.rawtext.trim().length < 6) {
                        this.errorMessage = "Content is too short, Please add atleast 6 character."; // Show validation message below textarea
                        this.isTextareaEmpty = true;
                        this.serverErrorMessage = "Content is too short, Please add at least 6 character.";
                        this.yourErrorHeaderVariable = "Validation Error";
                        this.$refs.errorPopup.showError = true;
                    } else {
                        this.serverErrorMessage = "Something went wrong. Please try again and reload.";
                        this.yourErrorHeaderVariable = "Fetal Error";
                    }

                    // Show the error popup for other errors
                    this.$refs.errorPopup.showError = true;
                });
        }
    }
};

</script>
<style>
.ql-snow.ql-editor {
    height: 250px !important;
}
.ql-container {
    height: auto !important;
    height: 250px !important;
}
.ql-container.ql-snow {
    border: none!important;
    height: 250px !important;
}
.ql-toolbar.ql-snow{
    border: none!important;
    border-bottom: 1px solid rgb(229 231 235);
}
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
}

.dropdown-content a {
    color: black;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    font-weight: 400;
}
.ql-editor {
    min-height: 250px !important;
}

.dropdown-content a:hover {
    background-color: #E2E2E2;
}

.show-dropdown .dropdown-content {
    display: block;
}
</style>
